<template>
  <div class="sign_inner dosiin_d-flex dosiin_page-min-height dosiin_padding-header-top">
    <div class="sign_left">
      <div class="sign_txt">
        <h3>Đăng nhập để thoả thích mua sắm và dễ dàng hơn</h3>
      </div>
    </div>
    <div class="sign_right">
      <div class="sign_right-box dosiin_pd-16">
        <LoginFormSMS />

        <!-- <hr class="line"> -->
        <div class="register-link dosiin_text-center">
          Bạn chưa có tài khoản?
          <router-link :to="{ name: 'register' }" class="primary-link_text"
            >Đăng ký</router-link
          >
        </div>
        <div class="condition">
          Bằng cách tiếp tục bạn đã Đồng ý với
          <br />
          <a href="/chinh-sach-bao-mat">Điều khoản dịch vụ, Chính sách bảo mật Dosiin</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginFormSMS from "@/components/forms/LoginSMSForm";
export default {
  components: {
    LoginFormSMS,
  },
};
</script>
