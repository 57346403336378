<template>
    <LoginSMSMB v-if="layout == 4"/>
    <LoginSMSPC v-else/>
</template>

<script>
    import LoginSMSMB from '@/pages/mb/auth/LoginSMS';
    import LoginSMSPC from '@/pages/pc/auth/LoginSMS';
    export default {
        components:{
            LoginSMSMB,
            LoginSMSPC
        },
    }
</script>