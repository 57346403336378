<template>
  <form class="dosiin_w-100" @submit.prevent="handleLogin" v-if="step == 1">
    <vue-tel-input
      v-model="phone"
      :onInput="onInputPhone"
      :dropdownOptions="{
        showFlags: true,
        showDialCodeInSelection: true,
      }"
      :inputOptions="{
        placeholder: 'Nhập số điện thoại của bạn',
      }"
    >
    </vue-tel-input>

    <div id="recaptcha-container"></div>

    <div class="group-check">
      <router-link :to="{ name: 'login' }" class="forget-pass"
        >Đăng nhập bằng mật khẩu</router-link
      >
    </div>

    <button class="primary-button button btn-login dosiin_mb-2" type="submit">
      <span v-if="!loading" class="primary-link_text">Đăng nhập</span>
      <pulse-loader v-else :loading="loading" color="#000000"></pulse-loader>
    </button>
  </form>

  <VueOtp2
    v-else-if="step == 2"
    ref="otpInput"
    input-classes="otp-input"
    separator=""
    :num-inputs="6"
    :should-auto-focus="true"
    :is-input-num="true"
    @on-failed="backToStep"
    @on-complete="verifyOtp"
    :phone="phoneObj.number"
    :timer="timerOTP"
    v-model="loading"
  />
</template>

<script>
import { _isEmail, _isVietnamesePhoneNumber, _minLength } from "@/utlis/validation";
import { reactive, ref, onMounted, inject } from "vue";
import { useToast } from "vue-toastification";
import AuthService from "@/services/auth";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

// components
import VueOtp2 from "@/components/forms/OTP.vue";
import VueTelInput from "@/components/forms/TelInput";
import ClickToRegister from "@/components/toasts/ClickToRegister";

export default {
  components: {
    VueOtp2,
    VueTelInput,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const step = ref(1);
    const authFirebase = getAuth();
    const phone = ref(null);
    const phoneObj = ref({});
    const phoneEl = ref(null);
    const loading = ref(false);
    const disable = ref(true);
    const timerOTP = 40;
    const toast = useToast();
    const mainRoute = inject("mainRoute");
    const validation = reactive({
      isPhone: true,
    });

    onMounted(() => {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignInSubmit();
          },
        },
        authFirebase
      );
    });

    async function handleLogin() {
      validation.isPhone = _isVietnamesePhoneNumber(phone.value.replace(/ +/g, ""));
      // wrong email and phone number
      if (!validation.isPhone) {
        toast.error("Số điện thoại không đúng định dạng");
        return;
      }

      loading.value = true;
      // check phone exits
      const res = await AuthService.checkUserExistWithPhone(
        phone.value.replace(/ +/g, "")
      );

      if (res.data && res.data.userExist) {
        sendOtp();
      } else {
        loading.value = false;
        toast.error({ component: ClickToRegister });
      }
    }

    async function sendOtp() {
      let phoneNumber = phoneObj.value.number;
      const appVerifier = window.recaptchaVerifier;
      await signInWithPhoneNumber(authFirebase, phoneNumber, appVerifier)
        .then(function (confirmationResult) {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          toast.success(
            "Vui lòng nhập mã xác thực đã được gửi tới số điện thoại của bạn"
          );
          step.value = 2;
          loading.value = false;
        })
        .catch(function (error) {
          toast.error("Lỗi , không thể gửi tin nhắn đến số điện thoại này");
          loading.value = false;
        });
    }

    function verifyOtp(value) {
      window.confirmationResult
        .confirm(value)
        .then(async function (result) {
          // User signed in successfully.
          let userOTP = result.user;
          loading.value = true;
          if (userOTP) {
            userOTP["originPhone"] = phone.value;
            const responseOTP = await AuthService.login(userOTP);
            if (typeof responseOTP == "undefined") {
              loading.value = false;
            } else if (responseOTP.data.accessToken) {
              localStorage.setItem("user", JSON.stringify(responseOTP.data.user));
              store.dispatch("account/login", responseOTP.data.user);
              loading.value = false;

              if (route.query.next) {
                // router.replace(decodeURIComponent(route.query.next));
                window.location.href = decodeURIComponent(route.query.next);
              } else {
                router.push({ name: mainRoute });
              }
            } else {
              loading.value = false;
            }
          }
        })
        .catch(function (error) {
          loading.value = false;
          toast.warning("Mã xác thực không đúng vui lòng kiểm tra lại");
          // User couldn't sign in (bad verification code?)
        });
    }

    function onInputPhone(phoneOrigin, phoneObject, input) {
      if (phoneObject?.formatted) {
        phone.value = phoneObject.formatted;
        phoneObj.value = phoneObject;
      }
    }

    const backToStep = (value) => {
      step.value = value;
    };

    return {
      phone,
      validation,
      handleLogin,
      loading,
      disable,
      step,
      phoneObj,
      timerOTP,
      //refs
      phoneEl,
      //methods
      verifyOtp,
      backToStep,
      onInputPhone,
    };
  },
};
</script>
